dmx.Component("typed", {

  attributes: {
    strings: {
      type: String,
      default: '',
    },

    speed: {
      type: Number,
      default: 0,
    },

    delay: {
      type: Number,
      default: 0,
    },

    backSpeed: {
      type: Number,
      default: 0,
    },

    backDelay: {
      type: Number,
      default: 700,
    },

    smartBackspace: {
      type: Boolean,
      default: false,
    },

    shuffle: {
      type: Boolean,
      default: false,
    },

    fade: {
      type: Boolean,
      default: false,
    },

    loop: {
      type: Boolean,
      default: false,
    },

    hidecursor: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    toggle () {
      this._typed.toggle();
    },

    stop () {
      this._typed.stop();
    },

    start () {
      this._typed.start();
    },
  },

  events: {
    complete: Event,
  },

  init () {
    const strings = this.props.strings.split(',');

    this._typed = new Typed(this.$node, {
      strings: this.props.strings.split(','),
      typeSpeed: this.props.speed,
      startDelay: this.props.delay,
      backSpeed: this.props.backSpeed,
      backDelay: this.props.backDelay,
      smartBackspace: this.props.smartBackspace,
      shuffle: this.props.shuffle,
      fadeOut: this.props.fade,
      loop: this.props.loop,
      showCursor: !this.props.hidecursor,
    });
  },

  performUpdate (updatedProps) {
    this._typed.destroy();
    this.init();
  },

  destroy () {
    this._typed.destroy();
  },

});
